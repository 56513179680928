@font-face {
  font-family: "sleek";
  src: url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap")
}
*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    font-family: sleek;
    color: white;
    z-index: -9999;
    background-image: url('./Graphic/images/mainPageBG.png');
    background-size: cover;
    bottom: 0;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -9999;
}
