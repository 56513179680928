.rate-wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.center-piece-frame{
    width: 80vw;
    height: 68vh;
    background-color: rgba(255, 255, 255, 0.178);
    backdrop-filter: blur(7px);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.33);
}
.center-piece{
    width: 74vw;
    height: 60vh;
    background-color: #282b30;
    border: 10px solid white;
    border-radius: 50px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    overflow: hidden;
    position: relative;
    z-index: 2;
}
.no-rates{
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    color: white;
    font-size: 4vmin;
}
.outfit{
    border-right: 1vw solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    min-width: 100%;
    width: 35vw;
    position: relative;
    overflow: hidden;
}
.outfit>img{
    width: auto;
    height: 80%;
    position: absolute;
    z-index: 2;
    border: 2px rgba(255, 255, 255, 0.398) solid;
}
.img-blur-bg{
    width: 500% !important;
    height: 500% !important;
    position: absolute;
    z-index: 1;
    filter: blur(30px);
}
.img-header{
    width: 100%;
    word-break: break-all;
    position: absolute;
    z-index: 4;
    height: 5vh;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.img-header p{
    position: absolute;
    width: 80%;
    margin: 0 !important;
    font-size: 4vmin;
    text-shadow: 0 0 10px black;
}
.caption{
    width: 100%;
    position: absolute;
    z-index: 4;
    height: 5vh;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.caption p {
    position: absolute;
    width: 80%;
    margin: 0 !important;
    font-size: 2.5vmin;
    text-shadow: 0 0 7.5px black;
}
.aspects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 4.5vmin;
    height: 100%;
    width: 40vw;
}
.style-occasion h6{
    text-align: center;
}
.aspects > * {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
}
.aspect{
    justify-content: center;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
}
.subcategory{
    margin-left: 1.5vw;
}
.rating-bar{
    max-width: 90%;
    height: 5vh;
    border: 3px solid white;
    background-color: rgba(255, 255, 255, 0.728);
    border-radius: 50px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    overflow: hidden;
    margin: auto;
}
.sub-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
}
.subs{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 94%;
    height: fit-content;
}
.cat_sub1{
    font-size: 1.5vmin;
    color: rgb(175, 175, 175);
    height: fit-content;
}
.cat_sub2{
    font-size: 1.5vmin;
    color: rgb(175, 175, 175);
    text-align: right;
    height: fit-content;
}
.score{
    color: gray;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.style-score-button{
    border: none;
    border-right: 5px solid rgba(128, 128, 128, 0.472);
    height: 100%;
    width: 100%;
    background-color: inherit;
    font-size: inherit;
    font-family: thick;
    color: gray;
    cursor: pointer;
    transition: 500ms;
}
.style-score-button:hover{
    background-color: rgba(0, 0, 255, 0.318);
}
.style-clicked{
    background-color: blue;
    color: white;
}

.creativity-score-button{
    border: none;
    border-right: 5px solid rgba(128, 128, 128, 0.472);
    height: 100%;
    width: 100%;
    background-color: inherit;
    font-size: inherit;
    font-family: thick;
    color: gray;
    cursor: pointer;
    transition: 500ms;
}
.creativity-score-button:hover{
    background-color: rgba(172, 255, 47, 0.318);
}
.creativity-clicked{
    background-color: rgb(157, 227, 50);
    color: white;
}

.suitability-score-button{
    border: none;
    border-right: 5px solid rgba(128, 128, 128, 0.472);
    height: 100%;
    width: 100%;
    background-color: inherit;
    font-size: inherit;
    font-family: thick;
    color: gray;
    cursor: pointer;
    transition: 500ms;
}
.suitability-score-button:hover{
    background-color: rgba(229, 0, 229, 0.318);
}
.suitability-clicked{
    background-color: rgb(229, 0, 229);
    color: white;
}

.submit-rating{
    margin-bottom: 3vh;
    height: 3vh;
    max-height: 50px;
    width: 10vw;
    min-width: 100px;
    border-radius: 50px;
    border: 3px solid white;
    background-color: rgba(0, 0, 255, 0.422);
    font-family: thick;
    font-size: .5em;
    color: white;
    transition: 500ms;
    cursor: pointer;
}
.submit-rating:hover{
    background-color: blue;
}
.style-occasion{
    justify-content: center;
    margin-top: 5%;

}

.style-occasion>h3{
    text-align: center;
}
.style-occasion>h6{
    overflow-wrap:break-word;
    overflow-x: hidden;
    max-width: 30ch;
    max-height: 11ch;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #4c525c #282b30;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 20px;
    position: absolute;
    max-height: 90%;
    height: 90%;

  }

  *::-webkit-scrollbar-track {
    background: #282b3000;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #4c525c;
    border-radius: 10px;
    border: 0px none #ff0000;
  }
