.signup-wrapper{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.23);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 10;
}
.signup-card{
    background-color: white;
    height: 40vh;
    width: 30vw;
    border-radius: 6vh;
    margin-bottom: 10vh;
    background-color: #282b30;
    border: 10px solid white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.signup-card h1{
    font-size: 3em;
}
.inputs{
    display: flex;
    flex-direction: column;
}
.signup-input{
    margin-bottom: 10px;
    font-family: thick;
    font-size: 1.25em;
}
.signup-labels{
    font-size: 1.5em;
}
.signup-button{
    height: 5vh;
    width: 10vw;
    border-radius: 50px;
    border: 3px solid white;
    background-color: rgba(0, 0, 255, 0.422);
    font-family: thick;
    font-size: 1.25em;
    color: white;
    transition: 500ms;
    cursor: pointer;
}
.signup-button:hover{
    background-color: blue;
}
.signup-form{
    width: 90%;
}
.signup-exit-wrapper{
    background-color: rgba(255, 255, 255, 0);
    z-index: 20;
    position: absolute;
}
.signup-exit{
    background-color: rgb(155, 0, 0);
    width: 100%;
    font-size: 1.5em;
    color: white;
    width: 1.25em;
    height: 1.25em;
    border: 3px solid white;
    border-radius: 30%;
    position: absolute;
    transform: translate(65vw,-28vh);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
    z-index: 20;
    transition: 250ms;
}
.signup-exit:hover{
    background-color: red;
}
