.login-wrapper{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.23);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}
.login-card{
    height: 30vh;
    width: 30vw;
    border-radius: 60px;
    margin-bottom: 10vh;
    background-color: #282b30;
    border: 10px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.login-card h1{
    font-size: 6.5vmin;
}
.inputs{
    display: flex;
    flex-direction: column;
}
.login-input{
    margin-bottom: 10px;
    font-family: thick;
    font-size: 2vmin;
}
.login-labels{
    font-size: 2.5vmin;
}
.login-button{
    height: 5vmin;
    width: 10vmin;
    border-radius: 50px;
    border: 3px solid white;
    background-color: rgba(0, 0, 255, 0.422);
    font-family: thick;
    font-size: 2vmin;
    color: white;
    transition: 250ms;
    cursor: pointer;
}
.login-button:hover{
    background-color: blue;
}
.login-form{
    width: 90%;
}
.login-exit-wrapper{
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0);
    z-index: 10;
    position: absolute;
}
.login-exit{
    background-color: rgb(155, 0, 0);
    font-size: 2.75vmin;
    color: white;
    width: 4.25vmin;
    height: 4.25vmin;
    border: 3px solid white;
    border-radius: 15px;
    position: absolute;
    top: 27vh;
    left: 65vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
    z-index: 99999;
    transition: 250ms;
}
.login-exit:hover{
    background-color: red;
}
