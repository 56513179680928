.create-outfit-form {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.create-outfit-form form {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.label-input {
  font-family: thick;
  display: flex;
  flex-direction: column;
}
.label-input label {
  font-size: 3vmin;
}
.label-input input {
  font-family: thick;
  height: 3.5vh;
  font-size: 2.5vmin;
}
.label-input input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.outfit-center-piece-create {
  width: 72vw;
  height: 60vh;
  background-color: #282b30;
  border: 10px solid white;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.left-side {
  overflow: hidden;
  border-right: 10px solid white;
  display: flex;
  position: relative;
}
.left-side-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 36vw;
  overflow: hidden;
}
.uploaded-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 30vw;
  height: auto;
  border: 2px rgba(255, 255, 255, 0.398) solid;
}
.uploaded-img-blur-bg {
  width: 500% !important;
  height: 500% !important;
  position: absolute;
  transform: translate(5px, 5px);
  filter: blur(30px);
}
.left-side-purple {
  position: absolute;
  width: 100%;
  height: 100%;
}
.left-side-people {
  position: absolute;
  height: 65%;
  width: 95%;
}
.create-input {
  width: 25vw;
  margin: auto;
}
.create-outfit-select {
  width: 70%;
  margin-top: 2vh;
  padding: 2%;
  height: fit-content;
  font-size: 2.5vmin !important;
  border-radius: 15px;
  margin-bottom: 0px;
  background-color: rgb(68, 4, 114);
}

.create-outfit-button {
  margin-top: 3vh;
  height: 5vh;
  width: 10vw;
  border-radius: 50px;
  border: 3px solid white;
  background-color: rgba(0, 0, 255, 0.422);
  font-family: thick;
  font-size: 2vmin;
  color: white;
  transition: 500ms;
  cursor: pointer;
}
.create-outfit-button:hover {
  background-color: blue;
}

.category-select {
  background-color: blueviolet;
}
