.foot{
    width: 100vw;
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.logo-img img{
    height: 100%;
    width: 100%;
}
